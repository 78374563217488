import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import AccordionComponent from '../../../../components/Accordion';
export const _frontmatter = {
  "title": "AWS cross-account access with IAM roles",
  "path": "/knowledge/tech/aws",
  "date": "2023-02-26T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws--cross-account-access-with-iam-roles",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws--cross-account-access-with-iam-roles",
            "aria-label": "aws  cross account access with iam roles permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS — cross-account access with IAM roles`}</h1>
        <h2 {...{
          "id": "overview",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#overview",
            "aria-label": "overview permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Overview`}</h2>
        <p>{`The following guide provides a step-by-step approach for creating cross-account access using IAM roles. Prior knowledge of IAM concepts and the process of assuming roles is required in this guide.`}</p>
        <h2 {...{
          "id": "enabling-cross-account-access-with-iam-roles",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#enabling-cross-account-access-with-iam-roles",
            "aria-label": "enabling cross account access with iam roles permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Enabling cross-account access with IAM roles`}</h2>
        <p>{`To enable cross-account access, an AWS Identity and Access Management (IAM) role is created in the account (Account A) that owns the AWS resource which needs to be accessed. This role is granted specific permissions to access the resource. Then, the owner of the second account (Account B) can create a policy that allows a user or another resource in Account B to assume the role in Account A. When the IAM role is assumed, temporary security credentials are provided that can be used to invoke the resource in Account A.`}</p>
        <p>{`For the case of API Gateway, the `}<inlineCode parentName="p">{`sts:AssumeRole`}</inlineCode>{` action is used to assume the IAM role in Account A, which provides temporary security credentials. These credentials can be used to invoke the API Gateway in Account A from Account B.`}</p>
        <h2 {...{
          "id": "best-guide-to-follow",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#best-guide-to-follow",
            "aria-label": "best guide to follow permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Best guide to follow`}</h2>
        <p>{`Follow this guide which is the one I followed when our attempts finally worked:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/premiumsupport/knowledge-center/api-gateway-iam-cross-account/"
            }}>{`https://aws.amazon.com/premiumsupport/knowledge-center/api-gateway-iam-cross-account/`}</a></li>
        </ul>
        <h3 {...{
          "id": "missing-pieces",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#missing-pieces",
            "aria-label": "missing pieces permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Missing pieces`}</h3>
        <p>{`Some unmentioned things in the guide.`}</p>
        <h4 {...{
          "id": "protecting-the-api-gateway-resource",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#protecting-the-api-gateway-resource",
            "aria-label": "protecting the api gateway resource permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Protecting the API Gateway resource`}</h4>
        <p>{`We enabled cross-account access, but the API Gateway resource isn't protected. To set the authorization method for the resource as AWS IAM, Go to the resource -> Method Request:`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "990px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6e713abf8ce873d1037dda2da71659cc/7a3d6/api-gateway-resource-auth-iam.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.33333333333333%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVQ4y41S2W7DMAzL///SXvYLxR6HDRuwo2kdO7GT2PHFgcaSukWLVoCQwzJJUWqmaYJzDt57hBBgzAhnLYQ4YugVjA34lROEtpBSQogORyHQti32+z2EEOUeM6WEZhzHUmiMKcB28ZB6RogJIUZIpSCkghknsHaeDPysMRoNrTWGYQBFMWOMaBbv4RaPNchiZo/Fx5JGa3i/IOcMvzi8fSs8vxxxVCOQU7nDszWbmS2LLyQ3IZfDhFPk7U1Khc+Pd+xef/C0O+DnoJBTRM4XgM5ZiEOLGMKmsC5gG3yyta6TmMwA5OUqKaPhMOhhTCf5W2nOmOcZfd9DKYWu66BUX/xNFemZQgKyUBev/HawAtJsErKmEP8rZifXsgCS3Vp7BrYCcvpcDYKthLfACiD3hzvFi6uHNSBXheqo9B7YmcJ6GDXg+v9yWLeyITMV1L7VgPX3I9GsHnGa1xTWRA8pJCB3jF7eA3xE4R9xV/rs0wJ0egAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "screenshot of enabling auth for a resource",
                "title": "screenshot of enabling auth for a resource",
                "src": "/static/6e713abf8ce873d1037dda2da71659cc/7a3d6/api-gateway-resource-auth-iam.png",
                "srcSet": ["/static/6e713abf8ce873d1037dda2da71659cc/5a46d/api-gateway-resource-auth-iam.png 300w", "/static/6e713abf8ce873d1037dda2da71659cc/0a47e/api-gateway-resource-auth-iam.png 600w", "/static/6e713abf8ce873d1037dda2da71659cc/7a3d6/api-gateway-resource-auth-iam.png 990w"],
                "sizes": "(max-width: 990px) 100vw, 990px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Deploy it after applying the above change.`}</p>
        <h4 {...{
          "id": "modifying-the-policy",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#modifying-the-policy",
            "aria-label": "modifying the policy permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Modifying the policy`}</h4>
        <p>{`After modifying the policy, you'll have to re-deploy your API in order for the changes to take effect.`}</p>
        <h4 {...{
          "id": "invalid-resource-arn",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#invalid-resource-arn",
            "aria-label": "invalid resource arn permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Invalid resource ARN`}</h4>
        <p>{`Make sure the ARN for the resource in the trust policy is correct. If it's not correct, you'll get the following error message:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "Message": "User: arn:<SOME_USER> is not authorized to perform: <ACTION> on resource: arn:<SOME_RESOURCE>"
}
`}</code></pre>
        <h2 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/premiumsupport/knowledge-center/api-gateway-iam-cross-account/"
            }}>{`Best guide on enabling cross-account access using IAM roles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=Lg4h3spbo_A"
            }}>{`YouTube video on enabling IAM auth for API Gateway`}</a></li>
          <li parentName="ul">{`A lot of ChatGPT :)`}</li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      